.auth {
    display: flex;
    justify-content: center;
    max-width: var(--wrapper-max-width);
    width: var(--wrapper-width);
    margin: 0 auto;
    padding: 0 .875rem;
}

.auth__title {
    margin-bottom: 2rem;
}

.auth__form {
    max-width: 25rem;
    flex-grow: 1;
}

.auth__form__group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.25rem;
}

.auth__form__label {
    margin-bottom: .5rem;
}

.auth__form__input {
    padding: .5rem;
    border: none;
    border-radius: 4px;
}

@media screen and (max-width: 26em) {
    .auth__title {
        font-size: var(--heading-lg-fz);
    }
}