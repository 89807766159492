.user-profile__info {
    display: flex;
    margin-bottom: 2rem;
}

.user-profile__info__avatar {
    border-radius: 50%;
}

/* Default Avatar */
.user-profile__info__avatar--default {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7rem;
    height: 7rem;
    margin-right: 1rem;
    background-color: #4b5563;
}

/* User Avatar */
.user-profile__info__avatar__icon--default {
    width: 2rem;
    height: 2rem;
    stroke: #fff;
}

.user-profile__info__avatar__img {
    width: 7rem;
    height: 7rem;
    margin-right: 1rem;
    border-radius: 50%;
    object-fit: cover;
}

.user-profile__info-sect__inner-container {
    display: flex;
}

.user-profile__info-sect--left {
    margin-right: 1.5rem;
    margin-bottom: .5rem;
}

.user-profile__info-sect__totals span {
    font-weight: 700;
}

.user-profile__info-sect__username {
    margin-bottom: .25rem;
    line-height: 1;
    color: var(--brand-orange);
}

.user-profile__info__bio {
    max-width: 75ch;
}

.user-profile__info__follow-btn--activated {
    opacity: .9;
    background-color: var(--supporting-txt-clr);
}

@media screen and (max-width: 35em) {
    .user-profile__info {
        flex-direction: column;
    }
}

@media screen and (max-width: 26em) {
    .user-profile__info-sect__inner-container {
        flex-direction: column;
    }

    .user-profile__info-sect--right {
        margin-bottom: .5rem;
    }

    .user-profile__info-sect {
        margin-bottom: 2rem;
    }
}