.main-about__section {
    padding: 6rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
}

.main-about__section:nth-of-type(odd) {
    background-color: var(--ultra-light-grey);
    color: var(--main-bg-clr);
}

.main-about__section:nth-of-type(even) {
    background-color: transparent;
    color: var(--ultra-light-grey);
}

.main-about__section__content {
    max-width: 50ch;
    width: var(--wrapper-width);
}

.main-about__section__title {
    margin-bottom: .5rem;
}

.main-about__section__fig {
    max-width: 40rem;
    width: var(--wrapper-width);
}

.main-about__section__fig__img {
    width: 100%;
    height: auto;
}

.wave-svg {
    display: block;
}

.wave-svg--top {
    margin-top: -1px;
    padding: 0;
    transform: rotate(180deg);
    color: var(--ultra-light-grey);
}

.wave-svg--bottom {
    transform: rotate(0deg);
    color: var(--ultra-light-grey);
}

@media screen and (max-width: 81.25em) {
    .main-about__section {
        flex-direction: column;
    }
}