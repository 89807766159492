:root {
    --grid-col-height: 375px;
    --card-hover-anim-dur: 425ms;
}

.masonry-grid {
    display: flex;
    flex-wrap: wrap;
    padding-left: .875rem;
    max-width: var(--wrapper-max-width);
    width: var(--wrapper-width);
    margin: 0 auto;
    gap: .875rem;
}

.masonry-grid__link {
    width: auto;
    height: var(--grid-col-height);
    flex: 1 0 auto;
    text-decoration: none;
}

.masonry-grid__link__fig {
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: var(--grid-col-height);
}

/* darkened overlay */
.masonry-grid__link__fig::before {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    content: "";
    background-image: linear-gradient(180deg, transparent, rgb(6 7 13 / .5));
    z-index: 5;
    visibility: hidden;
    opacity: 0;
    transition: opacity var(--card-hover-anim-dur) ease;
}

/* only show overlay on grid card hover */
.masonry-grid__link:hover figure::before {
    visibility: visible;
    opacity: 1;
}

.masonry-grid__link__img {
    grid-column: 1 / -1;
    grid-row: 1 / -1;

    object-fit: cover;
    width: 100%;
    height: 100%;
}

.masonry-grid__link__fig-caption {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    align-self: end;
    margin: 1rem 1.5rem;
    color: #fff;

    z-index: 5;
    display: flex;
    flex-direction: column;
    visibility: hidden;
    opacity: 0;
    transition: opacity var(--card-hover-anim-dur) ease;
}

/* show caption info on hover */
.masonry-grid__link:hover figcaption {
    visibility: visible;
    opacity: 1;
}

.masonry-grid__link__fig-caption__title {
    font-size: var(--heading-med-fz);
    text-transform: capitalize;
}

.masonry-grid__link__fig-caption__username {
    color: hsl(0, 0%, 85%);
}

@media screen and (max-width: 55em) {
    .masonry-grid {
        display: grid;
        grid-template-columns: 1fr;
        padding: 0;
        gap: 1rem;
    }

    .masonry-grid__link {
        margin: 0;
    }
}