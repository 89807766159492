.loading-icon {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: .5rem;
    background-color: var(--accent-lime);
    animation: spinner 1000ms ease-in-out infinite alternate;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg) scale(1);
    }

    50% {
        transform: rotate(180deg) scale(.75);
    }

    100% {
        transform: rotate(360deg) scale(1);
    }
}

.global-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}