.artwork-view__info__fig {
    margin-bottom: 2rem;
    max-width: 80rem;
}

.artwork-view__info__fig__img {
    width: 100%;
    height: auto;
}

.artwork-view__info__title {
    text-transform: capitalize;
}

.artwork-view__info__by {
    margin-right: .5rem;
    color: var(--supporting-txt-clr);
}

.artwork-view__info__author {
    display: inline-block;
    margin-bottom: .75rem;
    color: var(--brand-orange);
}

.artwork-view__info__social-data {
    display: flex;
    align-items: flex-end;
}

.artwork-view__info__social-data__container {
    display: flex;
    align-items: flex-end;
    cursor: pointer;

    opacity: .6;
    transition: opacity 110ms ease;
}

.artwork-view__info__social-data__container:hover {
    opacity: 1;
}

.like-heart-icon,
.favorite-star-icon {
    margin-right: .375rem;
    width: 1.5rem;
    height: 1.5rem;
}

/* Dynamic state-based icon statuses */
.like-heart-icon--full {
    fill: #fff;
}

.favorite-star-icon--full {
    fill: #fff;
}
/* End dynamic icon statuses */

.artwork-view__info__social-data__totals:first-of-type {
    margin-right: 1.5rem;
}

.artwork-view__info__desc {
    max-width: 75ch;
    margin-top: 1rem;
    margin-bottom: 1.25rem;
    line-height: 1.6;
}

.artwork-view__info__date {
    margin-bottom: 2rem;
    color: var(--supporting-txt-clr);
}

@media screen and (max-width: 35em) {
    .artwork-view__info__title {
        font-size: var(--heading-med-fz);
    }
}