.header {
    padding: 2rem 0;
    margin-bottom: 2rem;
}

.nav {
    margin: 0 auto;
    padding-left: .875rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: min(var(--wrapper-width), var(--wrapper-max-width));
}

.nav__logo-img {
    width: 13rem;
    height: auto;
}

.nav__list {
    display: flex;
    align-items: center;
    gap: 3ch;
    list-style: none;
}

.nav__link {
    padding: .5rem 0;
    color: inherit;
    text-decoration: none;
    font-size: 1.0625rem;
}

.log-out-btn {
    border: none;
    background: none;
    color: #fff;
    cursor: pointer;
}

.search-form {
    max-width: 25rem;
    width: 100%;
}

.search-form__input {
    width: 100%;
    padding: .5625rem .5rem;
    background-color: #f3f4f6;
    font-size: 1rem;
    border: none;
    caret-color: var(--main-bg-clr);
    border-radius: 4px;
    color: var(--main-bg-clr);
}

.search-form__input::placeholder {
    color: #6b7280;
}

@media screen and (max-width: 68em) {
    .nav {
        flex-direction: column;
        padding-left: 0;
    }

    .nav__logo-link,
    .search-form {
        margin-bottom: 1rem;
    }
}