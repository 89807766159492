.user-profile-main {
    max-width: var(--wrapper-max-width);
    width: var(--wrapper-width);
    margin: 0 auto;
    padding: 0 .875rem;
    aspect-ratio: 373 / 176;
}

@media screen and (max-width: 35em) {
    .user-profile-main {
        padding: 0;
    }
}