.artwork-view__comments-section {
    max-width: 80rem;
}

.artwork-view__comments-total {
    display: inline-flex;
    align-items: center;
    margin-bottom: .875rem;
}

.artwork-view__comments-total__title {
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1;
}

.dot {
    padding: 0 .5rem;
    color: var(--supporting-txt-clr);
}

.artwork-view__comments__user__logged-out {
    display: flex;
    align-items: center;
    gap: .875rem;
    margin-bottom: .5rem;
    padding: 2rem 3rem;
    background-color: var(--dark-grey);
}

.artwork-view__comments__user__logged-out__desc__link {
    text-decoration: none;
    color: inherit;
    font-weight: 700;
}

@media screen and (max-width: 35em) {
    .artwork-view__comments__user__logged-out {
        padding: .825rem 1rem;
    }
}