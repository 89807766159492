.comment-segment {
    display: flex;
    gap: .875rem;
    padding: 2rem 3rem;
    background-color: var(--dark-grey);
}

.comment-segment:not(:last-child) {
    border-bottom: 1px solid var(--main-bg-clr);
}

.comment-segment__info__username {
    display: inline-block;
    margin-right: 1.25rem;
    margin-bottom: 1rem;
}

.comment-segment__info__date {
    color: var(--supporting-txt-clr);
}

.comment-segment__info__desc {
    white-space: pre-wrap;
}

.comment-segment__info__delete-btn {
    margin-top: .5rem;
}

.comment-segment__info__delete-btn {
    display: flex;
    padding: .25rem 0;
    border: none;
    background: none;
    cursor: pointer;
}

.trash-icon {
    margin-right: .5rem;
    width: 1.5rem;
    height: 1.5rem;
    stroke: #D1D5DB;
    opacity: .8;
    transition: opacity 120ms ease;
}

.comment-segment__info__delete-btn:hover .trash-icon,
.comment-segment__info__delete-btn:hover .comment-segment__info__delete-btn__span {
    opacity: 1;
}

.comment-segment__info__delete-btn__span {
    align-self: flex-end;
    color: #fff;
    opacity: .8;
    text-transform: uppercase;
    letter-spacing: .08em;
    font-size: .875rem;
    font-weight: 700;
}

@media screen and (max-width: 35em) {
    .comment-segment {
        padding: .825rem 1rem;
    } 
}
