.artwork-view {
    margin: 0 auto;
    padding: 0 .875rem;
    max-width: var(--wrapper-max-width);
    width: var(--wrapper-width);
}

@media screen and (max-width: 32em) {
    .artwork-view {
        padding: 0;
    }
}